import "./src/scss/styles.scss"
import { globalHistory } from "@reach/router"

/**
 * Check most recent router action and, if the page being
 * loaded is a new page pushed onto the history stack,
 * scroll the window to the top.
 * This is a workaround for a Gatsby/reach-router issue which
 * opens new pages in the site scrolled down to the last position
 * that the previous page was viewed at -- rather than starting at
 * the top of the new page.
 */
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.action === "PUSH") {
    window.scrollTo({ top: 0 })
  }
  return false
}
export const onInitialClientRender = () => {
  globalHistory._onTransitionComplete()
}
